import {
  has,
  isPlainObject,
  get,
  isArray,
  set,
  isObject,
  isBoolean,
  isNumber,
  forEach,
  isString,
  isNil,
  map,
  isEmpty,
  cloneDeep,
  isEqual,
  merge,
} from 'lodash';
import {
  getFieldItem,
  getFieldRelation,
  getFilter,
  getFieldMap,
  queryField,
  getFieldLabelAndClass,
  getFieldLabel,
  getFieldClass,
  transItem,
  transObjectValue,
  getWhereMap,
} from './util';
import { formatTime, breadthFirstTraverse, getPageParams } from '../web-mobile/util';
import { execExpression, execExpressionDeep, isExpression } from './expression';
import { getValue, escapeSpecialChars, deepProcessEscapeSpecialChars } from '../graphql';
import { queryGql, easyQueryGql } from './queryUtil';
// import i18n from 'nges-common/src/i18n/index';
const _ = {
  has,
  isPlainObject,
  get,
  isArray,
  set,
  isObject,
  isBoolean,
  isNumber,
  forEach,
  isString,
  map,
  isEmpty,
};

let DataService = () => {};
let request = () => null;


DataService = require('../web/utils/api').DataService;
request = require('../web/utils/request').request;


const propSplit = '-';

function getMutationField({ json, objects = [] }) {
  const { fields = [] } = json;
  const mutationFields = [];
  fields.forEach((item) => {
    const field = item.field || item;
    const { style } = item;
    // 后续迭代将render替换filter字段，更具有语义，目前做兼容，render的属性merge进filter
    if (_.has(item, 'render')) {
      if (!_.has(item, 'filter')) {
        item.filter = item.render;
      } else {
        item.filter = {
          ...item.filter,
          ...item.render,
        };
      }
    }
    let itemFilter = item.filter;
    const hadFilter = !!itemFilter;
    if (!itemFilter) {
      itemFilter = {};
    }
    // field - 字段名  此处处理字段名(层级、别名、单选项)并返回当前字段信息
    const fieldItem = getFieldItem({
      field: itemFilter?.field || field,
      objects,
      json,
    });
    const prop = `${field.replaceAll('.', propSplit)}`;
    if (fieldItem) {
      let filter = {};
      if (!itemFilter.field) {
        itemFilter.field = field;
      }
      filter = {
        ...getFilter({ filter: itemFilter, objects, json }),
      };
      const query = getPageParams();
      const hadRecordType = has(itemFilter, 'record_type') || has(query, 'record_type');
      // 需要通过record_type来控制选项
      if (['SELECT_ONE', 'SELECT_MANY'].includes(filter.value_type) && hadRecordType) {
        const recordType = itemFilter?.record_type ?? query.record_type;
        const { object: mainObjName } = json;
        const { fields: mainObjFields } =
          objects.find((obj) => obj?.object?.name === mainObjName) || {};
        if (mainObjFields) {
          const recordTypeField = mainObjFields.find(
            (mainObjField) => mainObjField.name === 'record_type',
          );
          const recordTypeOpts = recordTypeField?.select_one_option?.options;
          if (recordTypeOpts) {
            const recordTypeConf = recordTypeOpts.find(
              (recordTypeOpt) => recordTypeOpt.value === recordType,
            );
            if (recordTypeConf && recordTypeConf.config) {
              let optConf;
              try {
                optConf = JSON.parse(recordTypeConf.config);
                optConf = optConf.find((opt) => opt.value === field);
                if (optConf) {
                  filter.options = optConf.children;
                }
              } catch (err) {
                console.error(err);
              }
            }
          }
        }
      }
      mutationFields.push({
        ...item,
        label: item.label || fieldItem.display_name || fieldItem.name,
        prop,
        filter,
        style,
        hadFilter,
        setMask: fieldItem?.text_option?.set_mask,
        isRelationField: false,
      });
    } else {
      const isRelationField =
        getFieldRelation({
          field: itemFilter?.field || field,
          objects,
          json,
        }) || false;
      const filter = { ...itemFilter };
      mutationFields.push({
        ...item,
        prop,
        filter,
        style,
        hadFilter,
        isRelationField,
      });
    }
  });
  return mutationFields;
}
function getQueryFields({ json = {} }) {
  const fieldMap = getFieldMap(json);
  const queryFields = queryField(fieldMap);
  return queryFields;
}
function isInput(componentType) {
  return ['TEXT', 'TEXTAREA', 'PASSWORD', 'NUMBER', 'IDCARD', 'DIGIT'].includes(componentType);
}
function isDate(componentType) {
  return [
    'DATETIMERANGE',
    'DATETIME',
    'DATE',
    'DATERANGE',
    'YEARS',
    'MONTHS',
    'YEAR',
    'MONTH',
    'MONTHRANGE',
    'TIME',
  ].includes(componentType);
}
function isSelectOne(componentType) {
  return componentType === 'SELECT_ONE';
}
function isSelectMany(componentType) {
  return componentType === 'SELECT_MANY';
}
function isSelectData(componentType) {
  return componentType === 'SELECT_DATA';
}
function isCascader(componentType) {
  return componentType === 'CASCADER';
}
function isFlattenCascader(componentType) {
  return componentType === 'FLATTEN_CASCADER';
}
function isSubMutation(componentType) {
  return componentType === 'MUTATION';
}
function isCustomCompnent(componentType) {
  return componentType === 'COMPONENT';
}
function isButton(componentType) {
  return componentType === 'BUTTON';
}
function isUpload(componentType) {
  return ['UPLOAD', 'ATTACHMENT'].includes(componentType);
}
function isSwitch(componentType) {
  return componentType === 'SWITCH';
}
function isSubordinate(componentType) {
  return componentType === 'SUBORDINATE';
}
function isTxmapPoi(componentType) {
  return componentType === 'TXMAP_POI';
}
function isCard(componentType) {
  return componentType === 'CARD';
}
function isTree(componentType) {
  return componentType === 'TREE';
}
const componentTypeAdjust = {
  isInput,
  isDate,
  isSelectOne,
  isSelectMany,
  isSelectData,
  isCascader,
  isSubMutation,
  isCustomCompnent,
  isButton,
  isUpload,
  isSwitch,
  isSubordinate,
  isTxmapPoi,
  isFlattenCascader,
  isCard,
  isTree,
};
// 根据表单类型初始化formData
const initFormDataWithType = ({ formData, prop, data, componentType, filter = {}, value }) => {
  if (isSelectOne(componentType)) {
    formData[prop] = _.isPlainObject(data) ? data : void 0;
  } else if (isSelectMany(componentType)) {
    formData[prop] = Array.isArray(data) ? [...data] : void 0;
  } else if (isDate(componentType)) {
    let getTimeData = (item) => ({
      value: item,
      label: item ? formatTime(item, filter?.format || 'yyyy年MM月dd日 HH:ii:ss') : item,
    });
    const timestamp = data?.value || value;
    // 时间formData初始化
    if (Array.isArray(timestamp)) {
      formData[prop] = timestamp.map((item) => getTimeData(item));
    } else {
      formData[prop] = getTimeData(value);
    }
    getTimeData = null;
  } else {
    formData[prop] = data;
  }
  return formData;
};
// 初始化formValue,formData,propMapField,等store中的对象
const initFormKey = (groupFields) => {
  const formValue = {};
  const formData = {};
  // 每个表单的唯一标识对应一个表单配置
  const propMapField = {};
  // 每个表单的监听执行函数，每当表单对象有变化都会执行
  const propMapWatchFunc = {};
  // 表单级联关系
  const cascaderMap = {};
  groupFields.forEach(({ fields: groupField }) => {
    groupField.forEach((item) => {
      const { prop, componentType, watch, filter } = item;
      propMapField[prop] = item;
      if (watch) {
        propMapWatchFunc[prop] = watch;
      }
      // 输入框表单初始化为空字符串，因为undefined不能清空表单
      const value = isInput(componentType) ? '' : void 0;
      formValue[prop] = value;
      // 级联的父级表单id，默认是field的值，有重复命名则加后缀，num从1开始递增：_${num}
      const cascaderSelector = filter.cascader_selector;
      if (cascaderSelector) {
        cascaderMap[cascaderSelector] = cascaderMap[cascaderSelector] || [];
        cascaderMap[cascaderSelector].push(prop);
      }
      initFormDataWithType({ formData, prop, data: value, componentType });
    });
  });
  return {
    formValue,
    formData,
    propMapField,
    propMapWatchFunc,
    cascaderMap,
  };
};
const initDefaultValue = ({ formValue, formData, groupFields, layoutJson, objects }) => {
  groupFields.forEach(({ fields: groupField }) => {
    groupField.forEach((item) => {
      const { prop } = item;
      // 计算表单formValue默认值
      if (_.has(item, 'default_value')) {
        const { default_value: defaultValue, componentType, field, filter } = item;
        const value = defaultValue;
        const data = getFieldLabelAndClass({
          json: layoutJson,
          objects,
          field,
          itemValue: value,
          filter,
        });
        formValue[prop] = value;
        initFormDataWithType({
          formData,
          prop,
          data,
          componentType,
          filter,
          value,
        });
      }
      // 计算表单formData默认值
      if (_.has(item, 'default_data')) {
        const { default_data: defaultData } = item;
        formData[prop] = defaultData;
      }
    });
  });
  return { formValue, formData };
};
// 检查是否数字字符串
const isNumeric = (value) => {
  // 直接检查是否为数字类型
  if (typeof value === 'number') return true;
  // 确保类型为字符串
  if (typeof value !== 'string') return false;
  // 如果字符串超长则必然无法正常变为数字
  if (typeof value === 'string' && value.length > 13) return false;
  // 检查字符串是否可以转换为数字
  return !isNaN(value) && !isNaN(parseFloat(value));
};
// 根据url参数初始化表单
const initFormFromQuery = async ({
  formValue,
  formData,
  groupFields,
  layoutJson,
  objects,
  propMapField,
}) => {
  const newFormValue = { ...formValue };
  const newFormData = { ...formData };
  const query = getPageParams();
  await Promise.all(
    groupFields.map(({ fields: groupField }) => {
      return Promise.all(
        groupField.map((item) => {
          return new Promise(async (re, rj) => {
            try {
              const { prop } = item;
              if (_.has(query, prop)) {
                const { componentType, field, filter } = item;
                let value = query[prop];
                if (isNumeric(value)) {
                  value = +value;
                }
                let isDataExit = true;
                let asyncData = null;
                if ([isSelectOne(componentType), isSelectMany(componentType)].some(Boolean)) {
                  const listJson = cloneDeep(propMapField[prop]?.filter?.json);
                  if (_.isPlainObject(listJson)) {
                    // 检查该对象是否存在
                    const {
                      where: whereConf,
                      wheres: wheresConf,
                      sub_query: subQueryConf,
                      object,
                    } = listJson;
                    const where = execExpressionDeep(whereConf) || {};
                    const wheres = execExpressionDeep(wheresConf);
                    const subQuery = execExpressionDeep(subQueryConf);
                    const valueKey = listJson?.render?.checkbox?.value_key || 'id';
                    let customWhere = '';
                    if (isSelectMany(componentType)) {
                      value = value.split(',');
                      customWhere = {
                        field: valueKey,
                        where_type: '_in',
                        value,
                      };
                    } else if (isSelectOne(componentType)) {
                      customWhere = {
                        field: valueKey,
                        where_type: '_eq',
                        value,
                      };
                    }
                    if (_.isArray(where?.wheres)) {
                      where.wheres.push(customWhere);
                    } else {
                      _.set(where, 'wheres', [customWhere]);
                    }
                    const gql = queryGql({
                      json: {
                        where,
                        wheres,
                        sub_query: subQuery,
                        ...listJson,
                      },
                      objects,
                    });
                    const res = await DataService({
                      query: gql,
                    });
                    if (isSelectMany(componentType)) {
                      asyncData = res?.[object]?._data;
                    } else if (isSelectOne(componentType)) {
                      asyncData = res?.[object]?._data?.[0];
                    }
                    if (!asyncData) {
                      isDataExit = false;
                    } else {
                      if (isSelectMany(componentType)) {
                        asyncData = asyncData.map((i) =>
                          transObjectValue({
                            data: i,
                            json: listJson,
                            objects,
                          }),
                        );
                      } else if (isSelectOne(componentType)) {
                        asyncData = transObjectValue({
                          data: asyncData,
                          json: listJson,
                          objects,
                        });
                      }
                    }
                  }
                } else if (isDate(componentType)) {
                  if (isNumeric(value)) {
                    value = +value;
                  }
                }
                if (isDataExit) {
                  const data =
                    asyncData ||
                    getFieldLabelAndClass({
                      json: layoutJson,
                      objects,
                      field,
                      itemValue: value,
                      filter,
                    });
                  newFormValue[prop] = value;
                  initFormDataWithType({
                    formData: newFormData,
                    prop,
                    data,
                    componentType,
                    filter,
                    value,
                  });
                } else {
                  // 检查过的对象若不存在则置为null
                  newFormValue[prop] = null;
                  newFormData[prop] = null;
                }
              }
              re(1);
            } catch (err) {
              console.error('initFormFromQuery with error:', err);
              rj(err);
            }
          });
        }),
      );
    }),
  );
  return { formValue: newFormValue, formData: newFormData };
};
// 从外部的mutationFormData初始化formValue和formData
const initFormValueFromFormData = ({ mutationFormData, formValue, formData, groupFields }) => {
  Object.keys(mutationFormData).forEach((key) => {
    if (_.has(formValue, key)) {
      formData[key] = mutationFormData[key];
    }
  });
  groupFields.forEach((groupField) => {
    groupField.fields.forEach((field) => {
      const { prop, filter, format_value: formatValue } = field || {};
      if (_.has(mutationFormData, prop)) {
        let fieldData = formData[prop];
        if (['SELECT_ONE', 'SELECT_MANY'].includes(field.componentType)) {
          let getValueFunc = (item) => {
            let itemValue;
            if (_.has(item, 'label') && _.has(item, 'value')) {
              // 静态单选多选
              itemValue = item.value;
            } else if (filter?.value) {
              // 普通元数据枚举选项
              itemValue = _.get(item, `${filter.value}`);
            } else if (filter?.json?.render?.checkbox) {
              // 列表布局选项
              const { value_key: valueKey = 'id' } = filter.json.render.checkbox;
              itemValue = _.get(item, `${valueKey}`);
            } else {
              // 如果对象包含label则返回对象的value
              itemValue = _.isPlainObject(item) && item?.label ? item?.value : item;
            }
            return itemValue;
          };
          if (Array.isArray(fieldData)) {
            formValue[prop] = fieldData.map((item) => getValueFunc(item));
          } else {
            // 单选枚举值补全属性
            if (!isPlainObject(fieldData)) {
              const label = getFieldLabel(mutationFormData, prop);
              const className = getFieldClass(mutationFormData, prop);
              fieldData = {
                label,
                value: fieldData,
                class: className,
              };
              formData[prop] = fieldData;
            }
            formValue[prop] = getValueFunc(fieldData);
          }
          getValueFunc = null;
        } else if (isDate(field.componentType)) {
          let formatDate = (timeData) => {
            if (isPlainObject(timeData)) {
              return timeData;
            }
            // 如果是时间戳则重新格式化成label，value的形式返回
            return isNil(timeData)
              ? timeData
              : { value: timeData, label: formatTime(timeData, filter.format) };
          };
          if (Array.isArray(fieldData)) {
            const datas = fieldData.map((i) => formatDate(i));
            formValue[prop] = datas.map((i) => i?.value);
            formData[prop] = datas;
          } else {
            const data = formatDate(fieldData);
            formValue[prop] = data?.value;
            formData[prop] = data;
          }
          formatDate = null;
        } else if (['TEXT'].includes(field.componentType)) {
          formValue[prop] = _.isPlainObject(fieldData) ? fieldData?.value : fieldData;
        } else if (typeof wx === 'undefined' && ['CASCADER'].includes(field.componentType)) {
          // web上的级联初始化
          const isSelectOne = field?.filter?.type.includes('one');
          if (Array.isArray(fieldData) && fieldData.length) {
            if (isSelectOne) {
              formValue[prop] = fieldData[fieldData.length - 1]?.value;
            } else {
              formValue[prop] = fieldData.map((i) => i.value).join(',');
            }
            formData[prop] = formValue[prop];
          }
        } else {
          formValue[prop] = fieldData;
        }
      }
      // 经过formatValue的表达式转换表单值
      if (isExpression(formatValue)) {
        const res = execExpression(formatValue, {
          $value: formValue[prop],
          $data: formData[prop],
          $source: mutationFormData,
          t: {
            formValue,
            formData,
          },
        });
        if (Array.isArray(res)) {
          formValue[prop] = res?.[0];
          formData[prop] = res?.[1];
        } else {
          formValue[prop] = res;
          formData[prop] = res;
        }
      }
    });
  });
  return {
    formValue,
    formData,
  };
};
// 布局中format_value（数据转换函数）的计算，通过转换函数将数据格式化为自己想要的数据结构
// value和data对应单个表单的值类型和对象类型
const getValueAndData = ({
  detailData,
  formatValue,
  fieldKey,
  detailValue,
  expParamsObj,
  withoutLabel,
  filterValue,
}) => {
  let value;
  let data;
  value = formatValue
    ? execExpression(formatValue, {
        $value: detailData,
        $data: detailData,
        $source: detailData,
        ...expParamsObj,
      })
    : value;
  // formatValue返回的是数组或者值类型
  if (Array.isArray(value)) {
    data = value[1];
    value = value[0];
  } else if (withoutLabel) {
    value = Array.isArray(detailData)
      ? detailData.map((i) => _.get(i, filterValue))
      : _.get(detailData, filterValue);
    data = detailData;
  } else {
    value = isNil(value) ? detailData : value;
    data =
      value === null || value === void 0
        ? value
        : { value, label: getFieldLabel(detailValue, fieldKey) };
  }
  return [value, data];
};
// 将元数据中列的枚举项补充到对象中
const transItemFromObj = ({ fields, detailData, object, objects }) => {
  let newFields = fields;
  if (!newFields) {
    newFields = Object.keys(detailData);
  }
  return transItem({
    json: {
      object,
      fields: newFields,
    },
    objects,
    item: detailData,
  });
};
const execFormatValueExpression = ({ formatValue, detailData, expParamsObj }) => {
  let value;
  let data;
  if (isExpression(formatValue)) {
    const res = execExpression(formatValue, {
      $value: detailData,
      $data: detailData,
      $source: detailData,
      ...expParamsObj,
    });
    if (Array.isArray(res)) {
      value = res?.[0];
      data = res?.[1];
    } else {
      value = res;
      data = res;
    }
  } else {
    value = detailData;
    data = value;
  }
  return { value, data };
};
/**
 * 从元数据获取数据后初始化到表单对象
 */
const initFormWithGqlResult = ({
  detailValue,
  groupFields,
  expParamsObj,
  handleAction = () => null,
  formValue,
  formData,
  objects = [],
  memoryStoreSet,
}) => {
  groupFields.forEach(({ fields: groupField }) => {
    groupField.forEach((field) => {
      const {
        prop,
        field: dataFieldKey,
        filter = {},
        componentType,
        format_value: formatValue,
      } = field;
      const detailObject = dataFieldKey;
      const fieldKey = dataFieldKey || prop;
      // 如果查询的数据中没有该key，且format_value没有设置，且非自定义组件类型表单
      // 则不做任何表单初始化操作，以防止覆盖原有定义的默认值default_value和default_data
      if (!has(detailValue, fieldKey) && isNil(formatValue) && !isCustomCompnent(componentType)) {
        return;
      }
      const detailData = _.get(detailValue, fieldKey);
      let data;
      let value;
      const {
        fields = [],
        detail_fields: itemDetailFields,
        label,
        value: filterValue = 'id',
      } = filter;
      const detailField = itemDetailFields || fields;
      const valueMapLabel = getFieldLabel(detailValue, fieldKey);
      if (isSelectOne(componentType)) {
        if (_.isPlainObject(detailData) || Array.isArray(detailData)) {
          let selectOneDetailData;
          if (_.isPlainObject(detailData)) {
            selectOneDetailData = detailData;
          } else if (Array.isArray(detailData)) {
            selectOneDetailData = detailData?.[0] || {};
          }
          data = {};
          if (Array.isArray(detailField) && detailField.length) {
            // 将详情中对应的对象转化成带label和calss的对象
            data = transItemFromObj({
              fields: detailField,
              detailData: selectOneDetailData || {},
              object: detailObject,
              objects,
            });
            Object.keys(detailField).forEach((detailFieldIndex) => {
              const detailFieldKey = detailField[detailFieldIndex];
              const itemFieldKey = fields[detailFieldIndex];
              // detail_fields有的key也需要更新到data中
              const dataKey = itemFieldKey || detailFieldKey;
              _.set(data, dataKey, _.get(selectOneDetailData, detailFieldKey));
              if (filterValue === dataKey) {
                value = _.get(data, dataKey);
              }
            });
            // 将选项中对应的对象转化成带label和calss的对象
            data = transItemFromObj({
              fields,
              detailData: data || {},
              object: '',
              objects,
            });
            // 经过formatValue的表达式转换表单值
            [value, data] = formatValue
              ? execExpression(formatValue, {
                  $value: value,
                  $data: data,
                  $source: detailData,
                  ...expParamsObj,
                })
              : [value, data];
            data.value = value;
            if (label) {
              data.label = getFieldLabel(data, label);
            }
          } else if (label) {
            value = getFieldLabel(selectOneDetailData, filterValue);
            value = formatValue
              ? execExpression(formatValue, {
                  $value: value,
                  $data: selectOneDetailData,
                  $source: detailData,
                })
              : value;
            data = {
              label: getFieldLabel(selectOneDetailData, label),
              value,
            };
          } else {
            console.warn(`${prop}: need detail_fields or fields props`);
            [value, data] = getValueAndData({
              detailData,
              formatValue,
              fieldKey,
              detailValue,
              expParamsObj,
              withoutLabel: true,
              filterValue,
            });
          }
        } else {
          [value, data] = getValueAndData({
            detailData,
            formatValue,
            fieldKey,
            detailValue,
            expParamsObj,
          });
        }
      } else if (isSelectMany(componentType) || isSubMutation(componentType)) {
        value = [];
        data = [];
        if (Array.isArray(detailData)) {
          if (Array.isArray(detailField) && detailField.length) {
            detailData.forEach((detailDataItem = {}) => {
              let dataItem = {};
              // 对象属性展平
              dataItem = transItemFromObj({
                fields: detailField,
                detailData: detailDataItem || {},
                object: detailObject,
                objects,
              });
              Object.keys(detailField).forEach((detailFieldIndex) => {
                const detailFieldKey = detailField[detailFieldIndex];
                const itemFieldKey = fields[detailFieldIndex];
                // detail_fields有的key也需要更新到data中
                const dataKey = itemFieldKey || detailFieldKey;
                const fieldValue = _.get(detailDataItem, detailFieldKey);
                _.set(dataItem, dataKey, fieldValue);
                if (filterValue === itemFieldKey || filterValue === detailFieldKey) {
                  dataItem.value = fieldValue;
                  value.push(dataItem.value);
                }
              });
              // 对象属性展平
              dataItem = transItemFromObj({
                fields,
                detailData: dataItem || {},
                object: '',
                objects,
              });
              data.push(dataItem);
            });
            [value, data] = formatValue
              ? execExpression(formatValue, {
                  $value: value,
                  $data: data,
                  $source: detailData,
                  ...expParamsObj,
                })
              : [value, data];
          } else if (label) {
            detailData.forEach((detailDataItem = {}) => {
              detailDataItem = detailDataItem || {};
              let detailDataItemValue = getFieldLabel(detailDataItem, filterValue);
              detailDataItemValue = formatValue
                ? execExpression(formatValue, {
                    $value: detailDataItemValue,
                    $data: detailDataItem,
                    $source: detailData,
                  })
                : detailDataItemValue;
              const detailDataItemLabel = getFieldLabel(detailDataItem, label);
              value.push(detailDataItemValue);
              data.push({
                label: detailDataItemLabel,
                value: detailDataItemValue,
              });
            });
          } else {
            console.warn(`${prop}: need detail_fields or fields props`);
            [value, data] = getValueAndData({
              detailData,
              formatValue,
              fieldKey,
              detailValue,
              expParamsObj,
              withoutLabel: true,
              filterValue,
            });
          }
        } else if (isString(detailData) && valueMapLabel && Array.isArray(valueMapLabel)) {
          value = detailData.split(',');
          data = value.map((item, index) => ({
            value: item,
            label: valueMapLabel[index],
          }));
        } else {
          [value, data] = getValueAndData({
            detailData,
            formatValue,
            fieldKey,
            detailValue,
            expParamsObj,
          });
        }
        if (typeof value === 'string') {
          value = value.split(',').filter(Boolean);
        }
        if (isSubMutation(componentType)) {
          value = data;
        }
        if (filter?.use_proxy === false) {
          data = memoryStoreSet(data);
        }
      } else if (isSelectData(componentType) || isCascader(componentType)) {
        value = [];
        data = [];
        if (Array.isArray(detailData)) {
          // 级联的key
          let childrenKey = '';
          // value的key
          let valueKey = '';
          // 子列表的key
          let childValueKey = '';
          // row配置
          let { row } = filter;
          // 树高度
          let preLevel = 1;
          breadthFirstTraverse({ children: detailData }, 'children', (detailDataItem) => {
            const currentLevel = detailDataItem.$height;
            if (currentLevel > 1) {
              if (currentLevel > preLevel) {
                preLevel = currentLevel;
                childrenKey = row.key;
                valueKey = row.value_key || 'id';
                row = row.children || {};
                childValueKey = row.value_key || 'id';
              }
              const dataItem = detailDataItem || {};
              const valueObjItem = { value: _.get(dataItem, valueKey) };
              dataItem.value = valueObjItem.value;
              if (childrenKey) {
                dataItem.children = _.isArray(dataItem[childrenKey]) ? dataItem[childrenKey] : [];
                valueObjItem.children = _.isArray(dataItem.children)
                  ? dataItem.children.map((i) => {
                      i.value = _.get(i, childValueKey);
                      return { value: i.value };
                    })
                  : [];
              }
              if (currentLevel === 2) {
                data.push(dataItem);
                value.push(valueObjItem);
              }
            }
          });
          [value, data] = formatValue
            ? execExpression(formatValue, {
                $value: value,
                $data: data,
                $source: detailData,
                ...expParamsObj,
              })
            : [value, data];
        } else {
          [value, data] = getValueAndData({
            detailData,
            formatValue,
            fieldKey,
            detailValue,
            expParamsObj,
          });
        }
        if (filter?.format_type !== 'string' && typeof value === 'string') {
          value = value.split(',').filter(Boolean);
        }
      } else if (isCustomCompnent(componentType)) {
        try {
          // 自定义组件编辑初始化
          const componentData = formatValue
            ? execExpression(formatValue, {
                $value: detailData,
                $data: detailData,
                $source: detailData,
                ...expParamsObj,
              })
            : detailData;
          value = componentData;
          data = componentData;
          handleAction({
            action: 'componentInit',
            prop,
            value,
            formValue,
            formData,
            detailValue,
          });
        } catch (err) {
          console.error(err);
          data = [];
        }
      } else if (isDate(componentType)) {
        [value, data] = getValueAndData({
          detailData,
          formatValue,
          fieldKey,
          detailValue,
          expParamsObj,
        });
        if (filter?.format && _.isPlainObject(data)) {
          data.label = formatTime(value, filter.format);
        }
        if (!data && filter?.format && Array.isArray(value)) {
          data = value.map((item) => ({
            label: formatTime(item, filter.format),
            value: item,
          }));
        }
      } else if (isInput(componentType)) {
        const res = execFormatValueExpression({
          formatValue,
          detailData,
          expParamsObj,
        });
        value = res.value;
        data = res.data;
      } else if (isUpload(componentType)) {
        const res = execFormatValueExpression({
          formatValue,
          detailData,
          expParamsObj,
        });
        value = res.value;
        if (!isNil(value)) {
          if (!value) value = [];
          if (!_.isObject(value)) {
            try {
              value = JSON.parse(value.replace(/\\/g, ''));
            } catch (e) {
              console.log(e);
            }
          }
          // 附件初始化type类型
          if (_.isArray(value)) {
            value.forEach((item) => {
              if (_.isPlainObject(item)) {
                item.type = item.type || /.*\.(.*)/.exec(item.name)?.[1]?.toLowerCase();
              }
            });
          }
        }
        data = value;
      } else {
        [value, data] = getValueAndData({
          detailData,
          formatValue,
          fieldKey,
          detailValue,
          expParamsObj,
        });
      }
      formValue[prop] = value;
      formData[prop] = data;
    });
  });
  return {
    formValue,
    formData,
  };
};

/**
 * 递归计算生成多级子对象gql语句
 */
const formatCascadeValueToGql = (value) => {
  if (_.isPlainObject(value)) {
    const gqlObjsArr = [];
    for (const k of Object.keys(value)) {
      gqlObjsArr.push(`${k}: ${formatCascadeValueToGql(value[k])}`);
    }
    return `{${gqlObjsArr.join(',')}}`;
  }
  if (_.isArray(value)) {
    const gqlObjsArr = [];
    value.forEach((item) => {
      gqlObjsArr.push(formatCascadeValueToGql(item));
    });
    return `[${gqlObjsArr.join(',')}]`;
  }
  if (_.isString(value)) {
    return `"${escapeSpecialChars(value)}"`;
  }
  return value;
};

const formatFieldValue = (value, field) => {
  if (_.isBoolean(value) || _.isNumber(value)) {
    return value;
  }
  if (isNil(value)) {
    return `null`;
  }
  if (!value) {
    return `""`;
  }
  if (typeof value === 'string') {
    return `"${escapeSpecialChars(value)}"`;
  }
  // js数组和observer对象数组
  if (Array.isArray(value) || (_.isObject(value) && _.has(value, 'length'))) {
    // 如果是对象数组，或者布局中配置了子表单方式，或者查元数据配置返回是关联字段的，则处理成多级级联数据。
    if (
      (value?.[0] && _.isObject(value[0])) ||
      field?.value_type === 'MUTATION' ||
      field?.isRelationField
    ) {
      return formatCascadeValueToGql(value);
    }
    if (
      // 纯数组类型的字段
      field?.valueType === 'ARRAY'
    ) {
      return formatCascadeValueToGql(value);
    }
    return `"${value.join(',')}"`;
  }
  return `${value}`;
};
// 与原来的值是否一致，满足条件的话不需要更新这个字段
const maskFieldShouldUpdate = ({ field, value, originFormData }) => {
  const { prop } = field;
  // 是否掩码而且是否是编辑而且编辑的时候与原来的值是否一致，满足条件的话不需要更新这个字段
  return field.setMask && _.has(originFormData, prop) && value === originFormData?.[prop];
};
// 获取graphql的待提交字段值
const getGraphqlFieldValue = ({ field, formValue }) => {
  const { prop, return_value: returnValue } = field;
  return formatFieldValue(returnValue !== void 0 ? returnValue : formValue[prop], field);
};
/**
 * 递归获取子对象（子表）gql语句
 */
const getSubMutationFields = ({ parentObject, groupFields = [], useStep, step }) => {
  // 获取需要级联更新的field对象
  const listHaveSubObject = groupFields.reduce((pre, { fields: groupField }) => {
    const groupFieldHaveSubObject = groupField.filter((field) => {
      const { parent_object: fieldParentObject, sub_object: subObject, ignore } = field;
      // 分步保存忽略非当前步骤的字段
      if (useStep && `${field.step}` !== `${step}`) {
        return false;
      }
      const isParent = fieldParentObject === parentObject;
      // 父级对象不能等于子对象，否则递归会死循环
      const isNotSameObject = fieldParentObject !== subObject;
      return isParent && field.sub_object && isNotSameObject && !ignore;
    });
    pre.push(...groupFieldHaveSubObject);
    return pre;
  }, []);
  if (listHaveSubObject.length) {
    const subObjectFields = listHaveSubObject.reduce((pre, field) => {
      const { sub_object: subObject, filter = {} } = field;
      const { include_special: includeSpecial } = filter;
      if (pre[subObject]) {
        return pre;
      }
      const sameVirtualFields = listHaveSubObject
        .filter((f) => f.sub_object === subObject)
        .map((f) => f.return_value || []);
      const sameVirtualFieldList = sameVirtualFields.reduce((preFields, f) => {
        // 相同子对象合并提交（一对多合并提交）
        if (Array.isArray(f)) {
          preFields.push(...f);
        }
        return preFields;
      }, []);
      const sameVirtualFieldObject =
        !sameVirtualFieldList.length &&
        sameVirtualFields.reduce((preField, f) => {
          // 一对一取最后一项提交
          if (_.isPlainObject(f)) {
            preField = f;
          }
          return preField;
        }, null);
      const subMutationFields = getSubMutationFields({
        parentObject: subObject,
        groupFields,
      });
      const isOneOnMany = Array.isArray(sameVirtualFieldList) && sameVirtualFieldList.length;
      const isOneOnOne = sameVirtualFieldObject;
      if (!pre[subObject]) {
        pre[subObject] = [];
      }
      if (isOneOnMany || isOneOnOne) {
        // insert语句子表是更新的时候需要该配置（字段带id是更新）
        let onConflictStr = '';
        if (_.has(field, 'on_conflict')) {
          const { on_conflict: onConflict } = field;
          const { columns: columns, update_columns: updateColumns } = onConflict || {};
          if (_.isArray(columns) && _.isArray(updateColumns)) {
            onConflictStr = `, _on_conflict: {_columns: [${columns.join(
              ',',
            )}], _update_columns: [${updateColumns.join(',')}]}`;
          }
        }
        pre[subObject].push(
          `${subObject}(_values: ${getValue(
            deepProcessEscapeSpecialChars(
              isOneOnMany ? sameVirtualFieldList : sameVirtualFieldObject,
            ),
            includeSpecial,
          )}${onConflictStr}) ${subMutationFields}`,
        );
      } else {
        pre[subObject].push(`${subObject}(_values: ${getValue([])}) ${subMutationFields}`);
      }
      return pre;
    }, {});
    const returnValue = `${Object.values(subObjectFields).join(' ')}`;
    return returnValue ? `{ ${returnValue} }` : '';
  }
  return '';
};
// 获取主表的graphql拼接字段
const getMutationFields = ({
  isUpdate = false,
  fieldsWouldRender,
  formValue,
  detailValue,
  originFormData,
  groupFields,
  attachmentFields = [],
  useStep,
  step,
}) => {
  const fields = [];
  fieldsWouldRender?.forEach((field) => {
    const { prop, filter = {}, parent_object: parentObject, ignore } = field;
    let { field: mutationField } = filter;
    const { include_special: includeSpecial } = filter;
    mutationField = mutationField || field.field;
    let value = _.has(field, 'return_value') ? field.return_value : formValue?.[prop];
    const isMaskFieldShouldUpdate = maskFieldShouldUpdate({
      field,
      value,
      originFormData,
    });
    if (isMaskFieldShouldUpdate) {
      // 是掩码表单而且值没有变化就不再传给后台
      return;
    }
    // 编辑
    if (isUpdate) {
      // 主表没有更新的字段不提交
      if (
        !isModify({
          field,
          detailValue,
          value,
        })
      ) {
        return;
      }
    }
    // 分步保存忽略非当前步骤的字段
    if (useStep && `${field.step}` !== `${step}`) {
      return;
    }
    // ignore为true代表只有交互，不会把字段拼接到graphql里提交
    if (!parentObject && !ignore) {
      // 附件字段
      const isUploadField = attachmentFields.some((item) => item.name === mutationField);
      if (isUploadField) {
        if (_.isArray(value)) {
          value = JSON.stringify(
            value.map((item) => ({
              name: item.name,
              url: item.publicUrl || item.url,
            })),
          ).replaceAll(`"`, `\\"`);
        }
        // 增加附件的return_value逻辑，用于自定义json
        value = _.has(field, 'return_value') ? field.return_value : value;
        // 如果value还是对象则需要自动转成字符串提交，return_value返回的可能是对象
        if (_.isObject(value)) {
          value = escapeSpecialChars(JSON.stringify(value));
        }
      } else if (isString(value) && includeSpecial) {
        value = escapeSpecialChars(value);
      }
      fields.push(`${mutationField}: ${formatFieldValue(value, field)}`);
    }
  });
  // 隐藏的需要提交的字段
  const hiddenFields =
    groupFields?.reduce((pre, group = {}) => {
      const { fields: groupField } = group;
      const hiddenFieldsNeedSubmit = groupField.filter((field) => {
        // 通过新增该属性来强制提交隐藏字段，兼容下面的历史问题
        if (field.hidden && field.force_submit) {
          return true;
        }
        // 有些表单隐藏的同时不需要提交，会通过条件切换是否显示，显示后需要提交（带filter代表是个会切换显示状态的有交互的表单）
        const isNeedSubmit = field.hidden && !field.hadFilter;
        // ignore为true代表不提交, 级联更新也不拼接在主对象中
        return isNeedSubmit && !field.ignore && !field.parent_object;
      });
      pre.push(...hiddenFieldsNeedSubmit);
      return pre;
    }, []) || [];
  hiddenFields.forEach((field) => {
    const { field: mutationField, filter = {} } = field;
    const { include_special: includeSpecial } = filter;
    // 默认用formValue里的值，有return_value就用return_value，没有再使用default_value提交
    let value = formValue[field?.prop];
    if (_.has(field, 'return_value')) {
      value = field.return_value;
    } else if (_.has(field, 'default_value')) {
      value = field.default_value;
    }
    if (maskFieldShouldUpdate({ field, value, originFormData })) {
      // 是掩码表单而且值没有变化就不再传给后台
      return;
    }
    // 编辑
    if (isUpdate) {
      // 主表没有更新的字段不提交
      if (
        !isModify({
          field,
          detailValue,
          value,
        })
      ) {
        return;
      }
    }
    // 分步保存忽略非当前步骤的字段
    if (useStep && `${field.step}` !== `${step}`) {
      return;
    }
    if (isString(value) && includeSpecial) {
      value = escapeSpecialChars(value);
    }
    fields.push(`${filter.field ?? mutationField}: ${formatFieldValue(value, field)}`);
  });
  console.log('===getMutationFields===', fields);
  return `{ ${fields.join(',')} }`;
};
// 主表字段是否更新了
const isModify = ({ field, detailValue, value }) => {
  const { field: detailField } = field;
  let originValue = _.get(detailValue, detailField);
  const valueKey = field?.filter?.value || field?.filter?.value_key || 'id';
  if (_.isPlainObject(originValue)) {
    originValue = _.get(originValue, valueKey);
  } else if (Array.isArray(originValue)) {
    originValue = originValue.map((item) => _.get(item, valueKey));
  }
  return !(formatFieldValue(originValue, field) === formatFieldValue(value, field));
};
// 内置插入元数据的graphql拼接函数
const getCreateGql = ({
  layoutJson,
  fieldsWouldRender,
  formValue,
  originFormData,
  groupFields,
  attachmentFields,
  useStep,
  step,
}) => {
  const { object } = layoutJson || {};
  const subMutationFields = getSubMutationFields({
    parentObject: object,
    groupFields,
    useStep,
    step,
  });
  const gqlFields = getMutationFields({
    fieldsWouldRender,
    formValue,
    originFormData,
    groupFields,
    attachmentFields,
    useStep,
    step,
  });
  const insertMutation = `mutation insert {${object}(_values: ${gqlFields}) ${subMutationFields}}`;
  return insertMutation;
};
// 内置更新元数据的graphql拼接函数
const getUpdateGql = ({
  layoutJson,
  fieldsWouldRender,
  formValue,
  detailValue,
  originFormData,
  groupFields,
  targetId,
  attachmentFields,
  useStep,
  step,
  gqlCompose,
}) => {
  const { object, id_key: key = 'id' } = layoutJson || {};
  const subMutationFields = getSubMutationFields({
    parentObject: object,
    groupFields,
    useStep,
    step,
  });
  const gqlFields = getMutationFields({
    fieldsWouldRender,
    formValue,
    detailValue,
    originFormData,
    groupFields,
    isUpdate: true,
    attachmentFields,
    useStep,
    step,
  });
  let whereStr = `{ ${key}: ${getValue(targetId)} }`;
  if (gqlCompose) {
    let newGqlCompose = {
      object,
      wheres: [
        {
          fields: [
            {
              field: key,
              where_type: '_eq',
              value: targetId,
            },
          ],
        },
      ],
    };
    newGqlCompose = merge(newGqlCompose, gqlCompose);
    const whereMap = getWhereMap(newGqlCompose);
    whereStr = whereMap?.[object]?._where || '{}';
  }
  const updateMutation = `mutation update {${object}(_where: ${whereStr} ,_set: ${gqlFields}) ${subMutationFields}}`;
  return updateMutation;
};
/**
 * 获取graphql拼接语句
 */
const getGql = ({
  isCreate = false,
  isEdit = false,
  layoutJson,
  fieldsWouldRender,
  formValue,
  detailValue,
  originFormData,
  groupFields,
  targetId,
  attachmentFields,
  useStep,
  step,
  gqlCompose,
}) => {
  let getCreateGqlFunc = () => {
    const createGql = getCreateGql({
      layoutJson,
      fieldsWouldRender,
      formValue,
      originFormData,
      groupFields,
      attachmentFields,
      useStep,
      step,
    });
    getCreateGqlFunc = null;
    return createGql;
  };
  let getUpdateGqlFunc = () => {
    const updateGql = getUpdateGql({
      layoutJson,
      fieldsWouldRender,
      formValue,
      detailValue,
      originFormData,
      groupFields,
      targetId,
      attachmentFields,
      useStep,
      step,
      gqlCompose,
    });
    getUpdateGqlFunc = null;
    return updateGql;
  };
  if (isCreate) {
    return getCreateGqlFunc();
  }
  if (isEdit || targetId) {
    return getUpdateGqlFunc();
  }
  return getCreateGqlFunc();
};
// 上一个表单与当前表单的合并
const composePreData = ({ formData, formDataKey, list, fieldKey }) => {
  let preFormData = formData || [];
  // 单选对象变成对象数组，统一使用对象数组处理
  if (_.isPlainObject(preFormData)) {
    preFormData = [preFormData];
  }
  (list || []).forEach((listItem, index) => {
    const listItemFieldKeyValue = _.get(listItem, fieldKey);
    const olreadyExitData =
      Array.isArray(preFormData) &&
      preFormData.find(
        (dataItem) => _.get(dataItem, formDataKey || fieldKey) === listItemFieldKeyValue,
      );
    if (olreadyExitData) {
      list[index] = {
        ...olreadyExitData,
        ...listItem,
      };
    }
  });
  return list;
};
// 编辑的原始表单与当前表单的合并
const composeOriginData = ({ targetId, originFormData, formDataKey, list, fieldKey }) => {
  if (targetId) {
    // 编辑的时候当前表单的初始值
    let originData = originFormData || [];
    // 单选对象变成对象数组，统一使用对象数组处理
    if (_.isPlainObject(originData)) {
      originData = [originData];
    }
    // 编辑中的数据可能包含某些状态字段，编辑的时候需要将选择的数据merge过去，不直接赋值
    (list || []).forEach((listItem, index) => {
      const listItemFieldKeyValue = _.get(listItem, fieldKey);
      const olreadyExitData =
        Array.isArray(originData) &&
        originData.find(
          (dataItem) => _.get(dataItem, formDataKey || fieldKey) === listItemFieldKeyValue,
        );
      if (olreadyExitData) {
        list[index] = {
          ...olreadyExitData,
          ...listItem,
        };
      }
    });
    originData = null;
  }
  return list;
};
// 通过options_from获取数据
const getOptionsFrom = async ({ optionsFrom, objects, filters, expParamsObj = {} }) => {
  if (_.isString(optionsFrom)) {
    const res = await DataService({
      query: `query {kv (key:{ _in: [${optionsFrom}]}) {value key}}`,
    });
    return JSON.parse(res?.kv?.[0]?.value) || [];
  }
  if (optionsFrom?.type === 'META_OBJECT' && optionsFrom.options) {
    const { object } = optionsFrom.options;
    let extCondition = {};
    if (optionsFrom.options?.distinct) {
      extCondition = {
        [object]: { _distinct: true },
      };
    }
    const json = execExpressionDeep(cloneDeep(optionsFrom.options), {
      ...expParamsObj,
      $filters: filters,
    });
    const gql = easyQueryGql(json, extCondition);
    const res = await DataService({
      query: gql,
    });

    const list = (res[object] || []).map((item) => {
      const row = transObjectValue({
        data: item,
        json,
        objects,
      });
      const labelKey = optionsFrom?.row?.label;
      const valueKey = optionsFrom?.row?.value;
      if (labelKey && valueKey) {
        row.label = get(row, labelKey);
        row.value = get(row, valueKey);
      }
      return row;
    });
    const response = get(optionsFrom, 'response');
    if (response) {
      return execExpression(response, {
        t: {
          list,
        },
      });
    }
    return list;
  }
  if (optionsFrom?.type === 'CMD') {
    const { cmd, payload, response = null } = optionsFrom;
    const remoteValue = await request(
      cmd,
      execExpressionDeep(payload, {
        ...expParamsObj,
        $filters: filters,
      }),
    );
    let result = remoteValue;
    if (response) {
      const newParamsObj = {
        t: {
          ...remoteValue,
        },
      };
      result = execExpressionDeep(response, newParamsObj);
    }
    const labelKey = optionsFrom?.row?.label;
    const valueKey = optionsFrom?.row?.value;
    if (labelKey && valueKey) {
      result = result.map((item) => ({
        ...item,
        label: get(item, labelKey),
        value: get(item, valueKey),
      }));
    }
    return result;
  }
  if (optionsFrom?.type === 'CUSTOM' && optionsFrom.options) {
    let { options } = optionsFrom;
    if (isExpression(options)) {
      options = execExpression(options, {});
      return options;
    }
    if (Array.isArray(options)) {
      return options;
    }
    return [];
  }
  if (optionsFrom?.type === 'META_DATA' && optionsFrom.options) {
    const { options: optionConfig = {} } = optionsFrom;
    const optionsFromMetaData = [];
    (optionConfig.fields || []).forEach((field) => {
      const { options } =
        getFilter({
          filter: {
            field,
          },
          objects,
          json: {
            object: optionConfig.object,
          },
        }) || {};
      if (options?.[0]?.label === '全部' && options[0]?.value === 'all') {
        options.shift();
      }
      optionsFromMetaData.push(...options);
    });
    return optionsFromMetaData;
  }
};
const formatNormalFieldRules = (rules = []) => {
  return rules.reduce((pre, rule) => {
    const { required, message, trigger } = rule;
    // 需要校验配置是否有问题
    pre.push({
      required: !!required,
      message,
      trigger: trigger || '',
    });
    return pre;
  }, []);
};
const formatCustomFieldRules = (rules = [], expParamsObj) => {
  return rules.map((rule) => {
    const { trigger } = rule;
    let validateTrigger = 'change';
    if (trigger !== void 0) {
      validateTrigger = trigger;
    }
    return {
      validateFunction: getCustomRuleFuc({
        conditions: rule.conditions,
        expParamsObj,
      }),
      trigger: validateTrigger,
    };
  });
};
const getCustomRuleFuc = ({ conditions = [], expParamsObj }) => {
  return (rule, value, data, callback) => {
    try {
      const len = conditions.length;
      let isError = false;
      if (len) {
        for (const conditionIndex in conditions) {
          const conditionObj = conditions[conditionIndex];
          const { condition, message } = adjustIsValid({
            condition: { ...conditionObj },
            value,
            data,
            expParamsObj,
          });
          if (condition) {
            callback(message);
            isError = true;
            break;
          }
        }
      }
      if (!isError) {
        callback();
      }
    } catch (err) {
      console.error(err);
      callback(`自定义条件判断失败，请检查表达式是否正确！: ${err.toString()}`);
    }
  };
};
const adjustIsValid = ({ condition, value, data, expParamsObj }) => {
  return execExpressionDeep(condition, {
    ...expParamsObj,
    $value: value,
    $data: data,
  });
};

// 内置校验规则函数
const standardFuncsMap = {
  // 级联每一级的必填校验
  'level-require': ({ params, value }) => {
    const requiredRules = params?.[0] || [];
    if (requiredRules) {
      const len = requiredRules.length;
      for (let i = 0; i < len; i++) {
        let newValue = value;
        const currentRule = requiredRules?.[i];
        const isSelectOne = currentRule?.is_one;
        newValue = isSelectOne ? [newValue] : newValue;
        if (Array.isArray(newValue)) {
          if (!newValue.some((item) => item?.length === currentRule?.level)) {
            return currentRule?.message;
          }
          // 所有已选节点的层级如果大于必填层级的，在必填层级上必须要有他们的祖先节点
          if (!isSelectOne) {
            const selectedLen = newValue.length;
            for (let j = 0; j < selectedLen; j++) {
              const selectedItem = newValue[j];
              if (selectedItem.length > currentRule.level) {
                const shouldExitNode = selectedItem.slice(0, currentRule.level);
                const findParentNode = newValue.find((sItem) => isEqual(sItem, shouldExitNode));
                if (!findParentNode) {
                  return currentRule?.message;
                }
              }
            }
          }
        } else {
          return requiredRules?.[i]?.message;
        }
      }
    }
  },
};

// 劫持时间组件里的选择日期时间，加上跨月判断
function choiceDate(onlyOneMonth, showToast) {
  return function (weeks) {
    if (onlyOneMonth) {
      const { before, after } = this.cale.multipleStatus;
      if ((before && !after) || (!before && after)) {
        const beforeMonth = this.cale.multipleStatus.before.split('-')?.[1];
        const afterMonth = weeks.fullDate.split('-')?.[1];
        if (beforeMonth && afterMonth && afterMonth !== beforeMonth) {
          const { msg } = onlyOneMonth;
          showToast && showToast(msg);
          return;
        }
      }
    }
    if (weeks.disable) return;
    this.calendar = weeks;
    this.calendar.userChecked = true;
    // 设置多选
    this.cale.setMultiple(this.calendar.fullDate, true);
    this.weeks = this.cale.weeks;
    this.tempSingleDate = this.calendar.fullDate;
    const { before, after } = this.cale.multipleStatus;
    this.tempRange.before
      = !before || (before && !after) || (before && before < after) ? before : after;
    this.tempRange.after
      = !after || (after && !before) || (after && after > before) ? after : before;
    this.change();
  };
}

export {
  getMutationField,
  getQueryFields,
  componentTypeAdjust,
  initFormDataWithType,
  initFormKey,
  initDefaultValue,
  initFormValueFromFormData,
  getValueAndData,
  initFormWithGqlResult,
  formatFieldValue,
  maskFieldShouldUpdate,
  getGraphqlFieldValue,
  getSubMutationFields,
  getMutationFields,
  getCreateGql,
  getUpdateGql,
  getGql,
  composePreData,
  composeOriginData,
  transItemFromObj,
  initFormFromQuery,
  getOptionsFrom,
  formatNormalFieldRules,
  formatCustomFieldRules,
  standardFuncsMap,
  choiceDate,
};
